<template>
  <div>
    <div class="pt-4">
      <providers-tables-tabs @current-tab="currentTab = $event" />
      <keep-alive>
        <component :is="providersTable" />
      </keep-alive>
    </div>
    <div class="secondary-lighten pt-6">
      <help-button v-if="helpUrls.traffic_dsp_help" class="pt-6" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Providers from './Tables/Providers.vue'
  import ProvidersBillingStatistics from './Tables/ProvidersBillingStatistics.vue'
  import ProvidersTablesTabs from '@/views/Providers/ProvidersTables/ProviderTablesTabs.vue'
  import HelpButton from '../HelpButton.vue'

  export default {
    name: 'ProvidersTables',
    components: {
      ProvidersTablesTabs,
      Providers,
      ProvidersBillingStatistics,
      HelpButton
    },
    data() {
      return {
        currentTab: ''
      }
    },
    computed: {
      ...mapGetters('settings', ['helpUrls']),
      providersTable() {
        return {
          providers: Providers,
          billing_statistics: ProvidersBillingStatistics
        }[this.currentTab]
      }
    }
  }
</script>
