import Approval from '@/services/classes/Approval.js'

export default class Provider {
  actions = {}
  adFormatType = ''
  id = null
  latestApproval = new Approval()
  methodType = ''
  name = ''
  endpoint = ''
  pricingModel = ''
  requestExample = ''
  responseExample = ''
  sspFormat = {}
  status = ''
  verticalType = ''

  constructor(data) {
    if (!data) return

    this.actions = {
      activable: data.actions?.activable ?? false,
      deletable: data.actions?.deletable ?? false,
      editable: data.actions?.editable ?? false,
      pausable: data.actions?.pausable ?? false
    }
    this.adFormatType = data.ad_format_type ?? ''
    this.endpoint = data.endpoint ?? ''
    this.id = data.id ?? null
    this.latestApproval = new Approval(data.latest_approval)
    this.methodType = data.method_type ?? ''
    this.name = data.name ?? ''
    this.pricingModel = data.pricing_model ?? ''
    this.requestExample = data.request_example ?? ''
    this.responseExample = data.response_example ?? ''
    this.sspFormat = {
      id: data.ssp_format?.id ?? null,
      name: data.ssp_format?.name ?? '',
      requestExample: data.ssp_format?.request_example ?? '',
      responseExample: data.ssp_format?.response_example ?? ''
    }
    this.status = data.status ?? ''
    this.verticalType = data.vertical_type ?? ''
  }

  get transformRequest() {
    return {
      method_type: this.methodType,
      name: this.name || null,
      ad_format_type: this.adFormatType,
      pricing_model: this.pricingModel,
      vertical_type: this.verticalType,
      ...(this.sspFormat.id
        ? { ssp_format_id: this.sspFormat.id }
        : {
          request_example: this.requestExample,
          response_example: this.responseExample
        })
    }
  }

  static statusTypes() {
    return [
      { value: 'active', label: 'main.statuses.active', color: 'primary white--text' },
      { value: 'archived', label: 'main.statuses.archived', color: 'default white--text' },
      { value: 'paused', label: 'main.statuses.paused', color: 'default' }
    ]
  }
}
