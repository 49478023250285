<template>
  <div class="d-flex justify-center text--black mb-13">
    <c-btn
      v-for="(tab, index) in tableTabs"
      :key="tab.id"
      :class="[
        'mb-2 text-body-2',
        { 'black--text': tab.value !== currentTab },
        { 'ml-2': index !== 0 }
      ]"
      :color="tab.value === currentTab ? 'primary' : 'secondary'"
      :label="tab.title"
      :icon-props="{
        icon: tab.icon,
        size: 18,
        color: tab.value === currentTab ? 'white' : 'black',
        class: 'mr-3'
      }"
      depressed
      @click="changeCurrentTab(tab.value)"
    />
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'ProvidersTablesTabs',
    components: { CBtn },
    data() {
      return {
        currentTab: 'providers'
      }
    },
    computed: {
      tableTabs() {
        return [
          {
            id: 1,
            title: `${this.$t('providers.providers')}`,
            value: 'providers',
            icon: '$earth-search'
          },
          {
            id: 2,
            title: `${this.$t('providers.billing_statistics')}`,
            value: 'billing_statistics',
            icon: '$billings'
          }
        ]
      }
    },
    created() {
      this.emitCurrentTab(this.currentTab)
    },
    methods: {
      changeCurrentTab(tab) {
        this.currentTab = tab
        this.emitCurrentTab(tab)
      },
      emitCurrentTab(tab) {
        this.$emit('current-tab', tab)
      }
    }
  }
</script>
