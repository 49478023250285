var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center text--black mb-13"},_vm._l((_vm.tableTabs),function(tab,index){return _c('c-btn',{key:tab.id,class:[
      'mb-2 text-body-2',
      { 'black--text': tab.value !== _vm.currentTab },
      { 'ml-2': index !== 0 }
    ],attrs:{"color":tab.value === _vm.currentTab ? 'primary' : 'secondary',"label":tab.title,"icon-props":{
      icon: tab.icon,
      size: 18,
      color: tab.value === _vm.currentTab ? 'white' : 'black',
      class: 'mr-3'
    },"depressed":""},on:{"click":function($event){return _vm.changeCurrentTab(tab.value)}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }