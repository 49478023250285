<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="providers"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :loading="isLoading"
      class="pa-5"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template #item.status="{ item }">
        <custom-chip-status small label :value="item.status" :settings="statusTypes" />
      </template>
      <template #item.latestApproval.status="{ item }">
        <custom-chip-status
          small
          label
          :value="item.latestApproval.status"
          :settings="approvalStatusTypes"
        />
      </template>
      <template #item.verticalType="{ item }">
        <span class="text-capitalize">{{ item.verticalType }}</span>
      </template>
      <template #item.adFormatType="{ item }">
        {{ $t(`main.ad_format_types.${item.adFormatType}`) }}
      </template>
      <template #item.pricingModel="{ item }">
        <custom-chip-status
          small
          label
          outlined
          :value="item.pricingModel"
          :settings="pricingModels"
        />
      </template>
      <template #item.actions="{ item }">
        <provider-actions :provider="item" @update-table="fetchProviders()" />
      </template>
    </v-data-table>
    <data-table-footer
      :items-per-page="options.itemsPerPage"
      :page-count="pageCount"
      :current-page="options.page"
      @change-items-per-page="changePagePaginator($event, 'itemsPerPage')"
      @change-current-page="changePagePaginator($event, 'page')"
    />
  </div>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import debounce from 'debounce'
  import datatableFormatter from '@clickadilla/components/utils/datatable-formatter.js'
  import { mapGetters, mapState } from 'vuex'
  import ProviderActions from '@/views/Providers/ProviderActions.vue'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import handleErrors from '@/services/handleErrors.js'
  import { providersRepository } from '@/services/repository-factory.js'
  import Provider from '@/services/classes/Provider.js'

  export default {
    name: 'Providers',
    components: { ProviderActions, DataTableFooter, CustomChipStatus },
    data() {
      return {
        providers: [],
        pageCount: 1,
        isLoading: false,
        statusTypes: Provider.statusTypes(),
        totalItemsCount: null,
        options: {
          page: 1,
          itemsPerPage: 15,
          sortBy: ['id'],
          sortDesc: [true]
        }
      }
    },
    computed: {
      ...mapGetters('approvables', ['approvalStatusTypes']),
      ...mapState('campaigns', ['statuses', 'pricingModels']),

      headers() {
        return [
          {
            value: 'id',
            text: 'ID',
            width: 80
          },
          {
            value: 'name',
            text: this.$t('main.name'),
            width: 110
          },
          {
            value: 'status',
            text: this.$t('main.status'),
            minWidth: '220px',
            width: 150,
            sortable: false
          },
          {
            value: 'latestApproval.status',
            data: 'approval_status',
            name: 'latestApproval.status',
            text: this.$t('main.approval_status'),
            minWidth: '250px',
            width: 160,
            sortable: false
          },
          {
            value: 'verticalType',
            data: 'vertical_type',
            text: this.$t('providers.vertical'),
            width: 115
          },
          {
            value: 'adFormatType',
            data: 'ad_format_type',
            text: this.$t('main.ad_format'),
            width: 165
          },
          {
            value: 'pricingModel',
            text: this.$t('providers.pricing_model'),
            data: 'pricing_model',
            width: 200
          },
          {
            value: 'sspFormat.name',
            text: 'SSP',
            data: 'ssp_format',
            width: 100
          },
          {
            value: 'endpoint',
            text: this.$t('providers.endpoint'),
            width: '100px',
            sortable: false
          },
          {
            value: 'actions',
            text: this.$t('main.actions'),
            width: 100,
            sortable: false
          }
        ]
      }
    },
    watch: {
      options() {
        this.debounceFetchTable()
      }
    },
    created() {
      this.debounceFetchTable = debounce(this.fetchProviders, 500)
    },
    methods: {
      async fetchProviders() {
        this.isLoading = true
        const params = datatableFormatter({
          headers: this.headers,
          options: this.options
        })
        try {
          const response = await providersRepository.table(params)
          this.providers = response.data?.map((provider) => new Provider(provider)) ?? []
          this.totalItemsCount = response.recordsFiltered
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      async changePagePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }
        await this.debounceFetchTable()
      }
    }
  }
</script>
