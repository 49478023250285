<template>
  <v-dialog :value="isShown" persistent max-width="600">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <c-btn text :label="$t('main.disagree')" @click="$emit('close-dialog')" />
        <c-btn class="primary" :label="$t('main.agree')" @click="$emit('remove-ad')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'AdsConfirmDialog',
    components: {
      CBtn
    },
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      body: {
        type: String,
        required: true
      }
    }
  }
</script>
