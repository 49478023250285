<template>
  <div>
    <h1 class="text-center text-subtitle-1 font-weight-bold">
      {{ $t('providers.billing_preview') }}
    </h1>
    <v-data-table
      :headers="headers"
      :items="subscriptionBills"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :loading="isLoading"
      class="pa-5"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template #item.subscriptionPlan="{ item }">
        <v-chip
          :color="item.subscription.plan.textColor"
          text-color="white"
          small
          label
        >
          <span v-html="item.subscription.plan.pickCurrentLocale(locale).name" />
        </v-chip>
      </template>
      <template #item.price="{ item }">
        <span class="font-weight-bold">${{ item.subscription.plan.price }}</span>
      </template>
      <template #item.limit="{ item }">
        <span class="font-weight-bold">{{ item.subscription.plan.transformMaxRequestsPerSecond }}</span>
      </template>
      <template #item.billingPeriod="{ item }">
        {{ item.subscription.plan.billingPeriodDays }}
        {{ $t('providers.billing_days') }}
      </template>
      <template #item.nextBillDate="{ item }">
        <span>{{ item.subscription.paidUntil }}</span>
      </template>
      <template #item.status="{ item }">
        <custom-chip-status
          label
          small
          :value="item.subscription.status"
          :settings="chipStatuses"
        />
      </template>
    </v-data-table>
    <data-table-footer
      :items-per-page="options.itemsPerPage"
      :page-count="pageCount"
      :current-page="options.page"
      @change-items-per-page="changePagePaginator($event, 'itemsPerPage')"
      @change-current-page="changePagePaginator($event, 'page')"
    />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import moment from 'moment'
  import debounce from 'debounce'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import datatableFormatter from '@clickadilla/components/utils/datatable-formatter.js'
  import handleErrors from '@/services/handleErrors.js'
  import { providersRepository } from '@/services/repository-factory.js'
  import SubscriptionPlanBill from '@/services/classes/SubscriptionPlanBill.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'

  export default {
    name: 'ProvidersBillingStatistics',
    components: {
      DataTableFooter, CustomChipStatus
    },
    data() {
      return {
        subscriptionBills: [],
        totalItemsCount: null,
        pageCount: 1,
        isLoading: false,
        options: {
          page: 1,
          itemsPerPage: 15,
          sortBy: ['id'],
          sortDesc: [true]
        }
      }
    },
    computed: {
      ...mapState('settings', ['locale']),
      headers() {
        return [
          {
            value: 'id',
            text: 'ID',
            width: 90
          },
          {
            value: 'subscriptionPlan',
            text: this.$t('providers.subscription_plan'),
            sortable: false
          },
          {
            value: 'price',
            text: this.$t('providers.price'),
            sortable: false
          },
          {
            value: 'limit',
            text: this.$t('providers.limit'),
            sortable: false
          },
          {
            value: 'billingPeriod',
            text: this.$t('providers.billing_period'),
            sortable: false
          },
          {
            value: 'nextBillDate',
            text: this.$t('providers.next_bill_date'),
            sortable: false
          },
          {
            value: 'status',
            text: this.$t('providers.status'),
            sortable: false
          }
        ]
      },
      chipStatuses() {
        return [
          {
            color: 'primary white--text',
            label: 'providers.active',
            value: 'active'
          },
          {
            color: 'secondary black--text',
            label: 'providers.canceled',
            value: 'canceled'
          }
        ]
      }
    },
    watch: {
      options() {
        this.debounceFetchTable()
      }
    },
    created() {
      this.debounceFetchTable = debounce(this.fetchBillingStatistics, 500)
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async fetchBillingStatistics() {
        this.isLoading = true
        const tableParams = datatableFormatter({
          headers: this.headers,
          options: this.options
        })
        const params = {
          start_date: moment().add(-360, 'days').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          ...tableParams
        }
        try {
          const response = await providersRepository.billingTable(params)
          this.subscriptionBills = response.data?.map((bill) => new SubscriptionPlanBill(bill)) ?? []
          this.totalItemsCount = response.recordsFiltered
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      async changePagePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }
        await this.debounceFetchTable()
      }
    }
  }
</script>
