import SubscriptionPlan from './SubscriptionPlan.js'

export default class SubscriptionPlanBill {
  date = ''
  id = null
  type = ''
  payment = {
    amount: null,
    comment: null,
    createdAt: '',
    isAutocharge: false,
    transactionType: '',
    type: ''
  }
  subscription = new SubscriptionPlan()

  constructor(data) {
    if (!data) return

    this.date = data.date ?? ''
    this.id = data.id ?? null
    this.type = data.type ?? ''
    this.payment = {
      amount: data.payment?.amount ?? null,
      comment: data.payment?.comment ?? null,
      createdAt: data.payment?.created_at ?? '',
      isAutocharge: data.payment?.is_autocharge ?? false,
      transactionType: data.payment?.transaction_type ?? '',
      type: data.payment?.type ?? ''
    }
    this.subscription = new SubscriptionPlan(data.subscription)
  }
}
