<template>
  <div class="d-flex">
    <div v-for="settingsAction in settingsActions" :key="settingsAction.value">
      <table-action
        :settings-action="settingsAction"
        @deletable-handler="dialogIsShown = true"
        @activable-handler="activate()"
        @pausable-handler="pause()"
      />
    </div>
    <ads-confirm-dialog
      :is-shown="dialogIsShown"
      :title="$t('providers.archive_provider_body', { name: provider.name })"
      :body="$t('providers.archive_provider_title', { name: provider.name })"
      @close-dialog="dialogIsShown = false"
      @remove-ad="removeProvider()"
    />
  </div>
</template>

<script>
  import TableAction from '@clickadilla/components/ui/TableAction.vue'
  import AdsConfirmDialog from '@/components/ads/AdsConfirmDialog.vue'
  import Provider from '@/services/classes/Provider.js'
  import { providersRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'ProviderActions',
    components: {
      AdsConfirmDialog,
      TableAction
    },
    props: {
      provider: {
        type: Provider,
        required: true
      }
    },
    data() {
      return {
        dialogIsShown: false,
        removeIsLoading: false,
        activateIsLoading: false,
        pausableIsLoading: false
      }
    },
    computed: {
      settingsActions() {
        return [
          {
            value: 'activable',
            icon: 'mdi-play',
            text: this.$t('providers.activate_provider', { name: this.provider.name }),
            color: 'primary',
            method: 'activable-handler',
            isLoading: this.activateIsLoading,
            disabled: this.provider.actions.activable
          },
          {
            value: 'pausable',
            icon: 'mdi-pause',
            text: this.$t('providers.pause_provider', { name: this.provider.name }),
            color: 'warning',
            method: 'pausable-handler',
            isLoading: this.pausableIsLoading,
            disabled: this.provider.actions.pausable
          },
          {
            value: 'deletable',
            icon: 'mdi-delete',
            text: this.$t('providers.action_delete', { name: this.provider.name }),
            color: 'error',
            method: 'deletable-handler',
            isLoading: this.removeIsLoading,
            disabled: this.provider.actions.deletable
          }
        ]
      }
    },
    methods: {
      async activate() {
        this.activateIsLoading = true
        try {
          await providersRepository.activate(this.provider.id)
          this.$emit('update-table')
        } catch (error) {
          handleErrors(error)
        }
        this.activateIsLoading = false
      },
      async pause() {
        this.pausableIsLoading = true
        try {
          await providersRepository.pause(this.provider.id)
          this.$emit('update-table')
        } catch (error) {
          handleErrors(error)
        }
        this.pausableIsLoading = false
      },
      async removeProvider() {
        this.removeIsLoading = true
        try {
          await providersRepository.delete(this.provider.id)
          this.$emit('update-table')
          this.$showSuccessNotification(
            this.$t('providers.archived_provider', { name: this.provider.name })
          )
        } catch (error) {
          handleErrors(error)
          this.dialogIsShown = false
        }
        this.removeIsLoading = false
      }
    }
  }
</script>
