<template>
  <div :class="['pt-11 secondary-lighten', { 'background-paddings-desktop': isLgAndUp }]">
    <div :class="['text-center mb-7 px-4 px-lg-0', { 'info-desktop': isLgAndUp }]">
      <p class="text-subtitle-1 font-weight-medium mb-2">
        {{ $t('providers.traffic_dsp') }}
      </p>
      <p :class="['text-body-2 mb-2', { 'text--secondary': isLgAndUp }]">
        {{ $t('providers.dsp_description', { name: adNetwork.name }) }}
      </p>
    </div>
    <div class="d-flex justify-center mb-9 pt-lg-14">
      <promo-header-image v-if="isLgAndUp" />
      <promo-header-image-mobile v-else />
    </div>
    <v-divider v-if="!isLgAndUp" class="mx-5 white" />
    <div class="py-9">
      <p class="text-subtitle-1 text-center mb-4 font-weight-bold">
        {{ $t('providers.opportunities') }}
      </p>
      <div
        class="d-flex flex-column align-center flex-lg-row align-lg-stretch justify-lg-center"
      >
        <div
          v-for="(headerItem, index) of headerItems"
          :key="index"
          :class="[
            'header-item d-flex align-center white rounded mb-2 px-4 py-5',
            { 'ml-lg-5': isLgAndUp && index !== 0 }
          ]"
        >
          <div class="icon-round d-flex justify-center align-center primary">
            <v-icon color="white" size="20">
              {{ headerItem.icon }}
            </v-icon>
          </div>
          <span class="promo-text text-body-2 ml-5">{{ headerItem.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PromoHeaderImageMobile from './PromoHeaderImageMobile.vue'
  import PromoHeaderImage from './PromoHeaderImage.vue'

  export default {
    name: 'ProvidersPromoHeader',
    components: { PromoHeaderImageMobile, PromoHeaderImage },
    computed: {
      ...mapState('settings', ['adNetwork']),
      headerItems() {
        return [
          {
            text: this.$t('providers.promo_text_1'),
            icon: '$task-square'
          },
          {
            text: this.$t('providers.promo_text_2'),
            icon: '$branches'
          },
          {
            text: this.$t('providers.promo_text_3'),
            icon: '$rtb'
          }
        ]
      },
      isLgAndUp() {
        return this.$vuetify.breakpoint.lgAndUp
      }
    }
  }
</script>

<style lang="scss" scoped>
  .background-paddings-desktop {
    padding: 36px 50px 0 !important;
  }
  .info-desktop {
   position: absolute;
   right: 104px;
   width: 388px;
   text-align: left !important;
  }
  .promo-text {
    width: 172px;
  }
  .header-item {
    min-width: 304px;
  }
  .icon-round {
    width: 34px;
    height: 34px;
    border: 1px solid var(--v-primary-base);
    border-radius: 50%;
  }
</style>
